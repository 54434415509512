<template>
  <div class="px-4 pb-6">
    <div class="font-medium-16 gray10--text mb-2 text-center">
      {{ $t("panel.dashboardPage.assetsWidget.assetsModal.editAssets") }}
    </div>

    <div class="font-regular-12 gray7--text mb-6 text-center">
      {{ $t("panel.dashboardPage.assetsWidget.assetsModal.editAssetsDesc") }}
    </div>

    <v-row class="mb-8 px-6 currencies">
      <v-col cols="6" md="4" v-for="(asset, index) in allAssets" :key="index">
        <div
          class="pt-1 px-1 pb-3 custom-border cursor-pointer text-center"
          :class="{
            'active-border': checkIsSelectedAsset(asset.currencyId),
          }"
          @click="addToSelectedAssets(asset.currencyId)"
        >
          <v-row no-gutters>
            <v-col cols="1">
              <v-icon
                v-if="checkIsSelectedAsset(asset.currencyId)"
                size="20"
                class="currencyCheckIcon"
                >$filledCheckMark</v-icon
              >
            </v-col>

            <v-col cols="10" align-self="center">
              <v-img
                :src="asset.svglogo"
                width="24"
                height="24"
                class="mb-1 mx-auto"
              />
            </v-col>
          </v-row>

          <div class="font-regular-12 gray13--text text-truncate mb-1">
            {{ asset.cryptoName }}
          </div>

          <div class="font-regular-10 gray7--text">
            {{ asset.network }}
          </div>
        </div>
      </v-col>
    </v-row>

    <div
      class="d-md-flex align-center justify-space-between flex-md-nowrap flex-wrap"
    >
      <v-btn
        height="43"
        depressed
        color="primary"
        class="me-1 mb-3 mb-md-0 px-16 fill-width flex-shrink-1"
        @click="setSelectedAssets"
      >
        <span class="font-semiBold-14 px-16 px-md-6">
          {{ $t("panel.dashboardPage.assetsWidget.assetsModal.save") }}
        </span>
      </v-btn>

      <v-btn
        height="43"
        depressed
        color="gray2"
        class="px-16 fill-width flex-shrink-1"
        @click="close"
      >
        <span class="font-semiBold-14 px-14 px-md-4">
          {{ $t("panel.dashboardPage.assetsWidget.assetsModal.cancel") }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "assets-modal",
  props: {
    allAssets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedAssets: [],
    };
  },
  mounted() {
    const assets = JSON.parse(localStorage.getItem("assets"));
    if (assets && assets.length) {
      this.selectedAssets = assets;
    } else {
      this.allAssets.forEach((elem) => {
        this.selectedAssets.push(elem.currencyId);
      });
    }
  },
  methods: {
    checkIsSelectedAsset(itemId) {
      return this.selectedAssets.find((elem) => elem === itemId);
    },
    addToSelectedAssets(itemId) {
      if (this.checkIsSelectedAsset(itemId)) {
        this.selectedAssets = this.selectedAssets.filter(
          (elem) => elem !== itemId
        );
      } else {
        this.selectedAssets.push(itemId);
      }
    },
    setSelectedAssets() {
      if (!this.selectedAssets.length) {
        this.$toast.error(
          this.$t(
            "panel.dashboardPage.assetsWidget.assetsModal.atLeastOneSelectedCurrency"
          )
        );
        return;
      }
      localStorage.setItem("assets", JSON.stringify(this.selectedAssets));
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
@import "AssetsModal";
</style>
