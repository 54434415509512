<template>
  <v-card min-width="41%" class="pa-6 custom-scroll d-flex flex-column">
    <div class="d-flex justify-space-between mb-6">
      <span class="font-medium-16 gray10--text">
        {{ $t("panel.dashboardPage.transactionsWidget.recentTransactions") }}
      </span>

      <router-link icon color="transparent" to="/panel/transactions">
        <v-icon size="24" color="primary">$arrow</v-icon>
      </router-link>
    </div>

    <v-data-table
      :headers="headers"
      :items="data"
      :loading="loading"
      hide-default-footer
      class="dashboard-transactions-table"
      :class="{ 'no-data-wrapper-height': !data.length }"
      disable-sort
    >
      <template v-slot:item.date_type="{ item }">
        <div class="font-regular-12 gray13--text">{{ item.gatewayType }}</div>
        <div class="font-regular-10 gray7--text">
          {{ item.transactionTime | $renderDate }}
        </div>
      </template>

      <template v-slot:item.crypto_usd="{ item }">
        <div>
          <span class="font-regular-12 gray13--text me-1">{{
            item.cryptoAmount.toFixed(8)
          }}</span>
          <span class="font-regular-12 gray13--text">{{
            item.cryptoName
          }}</span>
        </div>
        <div class="font-regular-10 gray7--text">
          {{ item.localFiatCurrencyString }}
        </div>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip
          small
          :color="item.colors.bg_color"
          :text-color="item.colors.text_color"
        >
          <span class="font-regular-10">{{ item.transactionStatusText }}</span>
        </v-chip>
      </template>

      <template v-slot:no-results>
        <span class="gray7--text">
          {{ $t("panel.dashboardPage.transactionsWidget.noResult") }}
        </span>
      </template>

      <template v-slot:no-data>
        <v-icon size="62" color="gray5" class="mb-3">$itemInPage</v-icon>

        <div class="gray7--text">
          {{ $t("panel.dashboardPage.transactionsWidget.noData") }}
        </div>
      </template>

      <template v-slot:item.action="{ item }">
        <router-link
          :to="`/panel/transactions/deposit-details/${item.transactionId}`"
        >
          <span class="font-semiBold-12">
            {{ $t("panel.dashboardPage.transactionsWidget.details") }}
          </span>
        </router-link>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ReportService } from "@/services";
import renderDate from "@/mixins/renderDate";

export default {
  name: "assets-widget",
  mixins: [renderDate],
  components: {},
  data() {
    return {
      loading: false,
      page: 1,
      headers: [
        {
          align: "start",
          value: "date_type",
          width: 100,
        },
        {
          align: "start",
          value: "crypto_usd",
          width: 160,
        },
        {
          align: "start",
          value: "status",
          width: 71,
        },
        {
          align: "start",
          value: "action",
          width: 43,
        },
      ],
      data: [],
    };
  },
  mounted() {
    this.getTransactions();
  },
  methods: {
    getTransactions() {
      this.loading = true;
      const filters = {
        size: 10,
      };
      ReportService.getDepositTransactions(filters)
        .then((res) => {
          this.data = res.data.data.content;
          if (this.data.length) {
            this.data.map((elem) => {
              elem.colors = this.getStatusColors(elem.transactionStatus);
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getStatusColors(status) {
      const data = this.$t("panel.stateStatuses").find(
        (elem) => elem.status === status
      );
      if (data) return data;
      else
        return this.$t("panel.stateStatuses").find(
          (elem) => elem.status === "OTHER"
        );
    },
  },
};
</script>

<style scoped lang="scss">
@import "TransactionsWidget";
</style>
