<template>
  <v-container>
    <div class="d-lg-flex justify-lg-space-between px-0 px-md-2">
      <assets-widget class="mb-3 mb-lg-0 me-lg-5" />
      <transactions-widget />
    </div>
  </v-container>
</template>

<script>
import AssetsWidget from "./AssetsWidget";
import TransactionsWidget from "./TransactionsWidget";

export default {
  name: "dashboard",
  components: {
    AssetsWidget,
    TransactionsWidget,
  },
};
</script>
