<template>
  <v-card min-width="58.3%" class="pa-6 custom-scroll d-flex flex-column">
    <div class="mb-9">
      <span class="font-medium-16 gray10--text me-3">
        {{ $t("panel.dashboardPage.assetsWidget.assets") }}
      </span>

      <v-btn
        v-if="dataTable.length"
        icon
        width="16"
        height="16"
        color="primary"
        :loading="loading"
        @click="assetsModal = true"
      >
        <v-icon size="16" color="primary">$edit</v-icon>
      </v-btn>
    </div>

    <!--    <div v-if="totalAssetsValue" class="flex-column">-->
    <!--      <p class="mb-5 font-medium-14 gray13&#45;&#45;text">-->
    <!--        {{ $t("panel.dashboardPage.assetsWidget.totalAssetsValue") }} ${{-->
    <!--          totalAssetsValue-->
    <!--        }}-->
    <!--      </p>-->
    <!--      <v-divider />-->
    <!--    </div>-->

    <v-data-table
      :headers="headers"
      :items="dataTable"
      :loading="loading"
      hide-default-footer
      class="dashboard-assets-table"
      :class="{ 'no-data-wrapper-height': !dataTable.length }"
      disable-sort
    >
      <template v-slot:item.logo="{ item }">
        <div class="d-flex align-center">
          <img :src="item.svglogo" width="28" height="auto" class="me-2" />
          <span class="gray13--text me-1">{{ item.cryptoName }}</span>
          <span
            v-if="item.isSecondLayer"
            class="font-regular-10 gray7--text pt-1"
            >{{ item.network }}</span
          >
        </div>
      </template>

      <template v-slot:item.dollarRate="{ item }">
        <span class="font-regular-12 gray7--text">1 {{ item.iso }} = </span>
        <span class="font-medium-12 gray13--text"
          >${{ Number(item.instantAmount).toFixed(2) }}</span
        >
      </template>

      <template v-slot:item.crypto="{ item }">
        <div class="text-left d-inline-block">
          <div class="pe-8 text-right crypto-cell">
            <div>
              <span class="font-medium-14 gray10--text me-1">{{
                Number(item.remainAmount).toFixed(8)
              }}</span>
              <span class="font-medium-14 gray10--text">{{ item.iso }}</span>
            </div>
            <div class="asset-equivalent">
              <span class="font-regular-12 gray7--text">
                ≈
                {{
                  (
                    Number(item.remainAmount) * Number(item.instantAmount)
                  ).toFixed(5)
                }}
              </span>

              <span class="font-regular-12 gray7--text">
                {{ item.localCurrency }}
              </span>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:no-results>
        <span class="gray7--text">
          {{ $t("panel.dashboardPage.assetsWidget.noResult") }}
        </span>
      </template>

      <template v-slot:no-data>
        <v-icon size="62" color="gray5" class="mb-3">$wallet</v-icon>

        <div class="gray7--text">
          {{ $t("panel.dashboardPage.assetsWidget.noData") }}
        </div>
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn
          depressed
          color="gray2"
          width="77"
          height="26"
          class="withdraw-btn-border-radius"
          @click="goToWithdraw(item)"
        >
          <span class="font-semiBold-12">
            {{ $t("panel.dashboardPage.assetsWidget.withdraw") }}
          </span>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="assetsModal" width="468">
      <v-btn
        icon
        color="transparent"
        @click="closeModal"
        class="ml-auto d-block"
      >
        <v-icon size="24">$close</v-icon>
      </v-btn>

      <assets-modal :all-assets="data" @close="closeModal" />
    </v-dialog>
  </v-card>
</template>

<script>
import { AccountService } from "@/services";
import AssetsModal from "./AssetsModal";

export default {
  name: "assets-widget",
  components: {
    AssetsModal,
  },
  data() {
    return {
      totalAssetsValue: 91,
      loading: false,
      assetsModal: false,
      page: 1,
      headers: [
        {
          align: "start",
          value: "logo",
          width: 161,
        },
        {
          align: "start",
          value: "dollarRate",
          width: 185,
        },
        {
          align: "start",
          value: "crypto",
          width: 172,
        },
        {
          align: "end",
          value: "action",
          width: 77,
        },
      ],
      data: [],
      dataTable: [],
    };
  },
  mounted() {
    this.getAllAccounts();
  },
  methods: {
    setDataTable() {
      const assets = JSON.parse(localStorage.getItem("assets"));
      let data = [];
      if (assets && assets.length) {
        assets.forEach((item) =>
          data.push(this.data.find((elem) => elem.currencyId === item))
        );
        this.dataTable = [...data];
      } else this.dataTable = [...this.data];
    },
    closeModal() {
      this.setDataTable();
      this.assetsModal = false;
    },
    goToWithdraw(item) {
      this.$router.replace(
        `/panel/dashboard/withdrawal?from=${item.iso}&network=${item.network}`
      );
    },
    getAllAccounts() {
      this.loading = true;
      AccountService.getAllAccounts()
        .then((res) => {
          this.data = res.data.content;
          this.setDataTable();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "AssetsWidget";
</style>
